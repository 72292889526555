import { createSlice } from "@reduxjs/toolkit";

const initialDesignState = {
  approvedDesigns: [],
  rejectedDesigns: [],
  inProgressDesigns: [],
};

export const designSlice = createSlice({
  name: "design",
  initialState: initialDesignState,
  reducers: {
    updateApprovedDesigns(state, action) {
      state.approvedDesigns = action.payload;
    },
    updateRejectedDesigns(state, action) {
      state.rejectedDesigns = action.payload;
    },
    updateInProgressDesigns(state, action) {
      state.inProgressDesigns = action.payload;
    },
  },
});
