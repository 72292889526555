import React from "react";
import { Box, Input } from "@material-ui/core";

export const ContactSection = ({ customerDetails, handleCustomerDetail }) => {
  return (
    <>
      <Box>
        <label htmlFor="name">Customer Name:</label>
        <Input
          style={{ width: "70%" }}
          type="text"
          id="name"
          name="name"
          value={customerDetails.name}
          onChange={handleCustomerDetail}
        />
      </Box>
      <Box display="flex" flexWrap="wrap" gridGap={6}>
        <Box>
          <label htmlFor="contactPerson">Contact Person:</label>
          <Input
            type="text"
            id="contactPerson"
            name="contactPerson"
            value={customerDetails.contactPerson}
            onChange={handleCustomerDetail}
          />
        </Box>
        <Box>
          <label htmlFor="contactDetail">Contact Details:</label>
          <Input
            type="tel"
            id="contactDetail"
            name="contactDetail"
            value={customerDetails.contactDetail}
            onChange={handleCustomerDetail}
          />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" gridGap={6}>
        <Box>
          <label htmlFor="email">Email Address:</label>
          <Input
            type="email"
            id="email"
            name="email"
            value={customerDetails.email}
            onChange={handleCustomerDetail}
          />
        </Box>
        <Box>
          <label htmlFor="location">Location:</label>
          <Input
            type="text"
            id="location"
            name="location"
            value={customerDetails.location}
            onChange={handleCustomerDetail}
          />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" gridGap={6}>
        <Box>
          <label htmlFor="offerNo">Offer Number:</label>
          <Input
            type="text"
            id="offerNo"
            name="offerNo"
            value={customerDetails.offerNo}
            onChange={handleCustomerDetail}
          />
        </Box>
        <Box>
          <label htmlFor="date">Date:</label>
          <Input
            type="date"
            id="date"
            name="date"
            value={customerDetails.date}
            onChange={handleCustomerDetail}
          />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" gridGap={6}>
        <Box>
          <label htmlFor="reference">Reference:</label>
          <Input
            type="text"
            id="reference"
            name="reference"
            value={customerDetails.reference}
            onChange={handleCustomerDetail}
          />
        </Box>
        <Box>
          <label htmlFor="dated">Dated:</label>
          <Input
            type="date"
            id="dated"
            name="dated"
            value={customerDetails.dated}
            onChange={handleCustomerDetail}
          />
        </Box>
      </Box>
    </>
  );
};
