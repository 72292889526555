import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { NavigationBar } from "../Navbar/NavigationBar";
import { useLocation } from "react-router-dom";
import { LOGIN_PATH } from "Utils/constant";

export const BodyWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const authState = useSelector((state) => state.auth);

  return (
    <Box width="100%" display="flex" height="100%">
      {pathname !== LOGIN_PATH && authState.token && <NavigationBar />}
      <Box flex={1}>{children}</Box>
    </Box>
  );
};
