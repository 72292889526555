import { createSlice } from "@reduxjs/toolkit";

const initialProductionState = {
  orders: [],
  weightList: [],
  dispatchedOrders: [],
};

export const productionSlice = createSlice({
  name: "production",
  initialState: initialProductionState,
  reducers: {
    updateOrderStatus(state, action) {
      const selectedOrder = state.orders.find(
        (order) => order.id === action.payload.id
      );
      const otherOrders = state.orders.filter(
        (order) => order.id !== action.payload.id
      );
      state.orders = [
        ...otherOrders,
        { ...selectedOrder, ...action.payload.status },
      ];
    },
    updateDispatchedOrders(state, action) {
      state.dispatchedOrders = action.payload;
    },
    updateWeightList(state, action) {
      state.weightList = action.payload;
    },
  },
});
