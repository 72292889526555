import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import logo_crop from "Data/Logo/logo_crop.png";
import std_logo from "Data/Logo/std_logo.png";
import { CURRENCY_INR, PRIMARY_COLOR, documentStyles } from "Utils/constant";
import { Table, TR, TH, TD } from "@ag-media/react-pdf-table";

export const PdfDocument = ({ currency, customerDetails, itemList }) => {
  const netTotal =
    itemList.length > 0
      ? itemList?.reduce((total, curr) => {
          total = total + curr.total;
          return total;
        }, 0)
      : 0;

  return (
    <Document>
      <Page size="A4" style={{ ...documentStyles.page, padding: 28 }}>
        {/* header section */}
        <View style={{ gap: 15 }}>
          <View
            style={{
              ...documentStyles.header,
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Image style={{ width: 80, height: 50 }} src={logo_crop} />
            <View
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Text style={documentStyles.title}>
                NOVEL VALVES INDIA PVT LTD
              </Text>
              <Text style={documentStyles.subtitle}>
                Manufacturer Of All Types Of Industrial Valves
              </Text>
              <Text style={documentStyles.subtitle}>www.novelvalves.com</Text>
            </View>
            <Image style={{ width: 110, height: 50 }} src={std_logo} />
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "0 auto",
              fontSize: 14,
            }}
          >
            <View style={{ width: "85%", textAlign: "left" }}>
              <Text style={{ color: "gray" }}>To: {customerDetails.name}</Text>
            </View>
            <View style={{ width: "15%", gap: 4 }}>
              <Text
                style={{
                  color: PRIMARY_COLOR,
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Quotation
              </Text>
            </View>
          </View>
        </View>
        {/* divider line */}
        <View
          style={{
            borderBottom: `1px solid ${PRIMARY_COLOR}`,
            marginTop: 10,
          }}
        />
        {/* contact section */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 auto",
            padding: "22 0",
            fontSize: 9.5,
          }}
        >
          <View style={{ width: "65%", color: "red", gap: 4 }}>
            <Text>Contact Person: {customerDetails.contactPerson}</Text>
            <Text>Contact Details: {customerDetails.contactDetail}</Text>
            <Text style={{ color: "black" }}>
              Contact Email: {customerDetails.email}
            </Text>
            <Text style={{ color: "black" }}>
              Location: {customerDetails.location}
            </Text>
          </View>
          <View style={{ width: "35%", color: "red", gap: 4 }}>
            <Text>Offer No: {customerDetails.offerNo}</Text>
            <Text>Date: {customerDetails.date}</Text>
            <Text style={{ color: "black" }}>
              Reference: {customerDetails.reference}
            </Text>
            <Text style={{ color: "black" }}>
              Dated: {customerDetails.dated}
            </Text>
          </View>
        </View>
        {/* divider line */}
        <View style={{ borderBottom: `1px solid ${PRIMARY_COLOR}` }} />
        {/* quotation body */}
        {itemList.length > 0 && (
          <View>
            <Table style={documentStyles.table}>
              <TH style={documentStyles.tableHeading}>
                <TD style={documentStyles.tableSrNo}>Sr.no</TD>
                <TD style={documentStyles.tableTitle}>Description</TD>
                <TD style={documentStyles.tableQty}>Qty</TD>
                <TD style={documentStyles.tableUnitPrice}>Unit Price</TD>
                <TD style={documentStyles.tableTotal}>Net Price</TD>
              </TH>
              <View
                style={{
                  borderBottom: `1px solid ${PRIMARY_COLOR}`,
                  marginTop: 10,
                }}
              />
              {itemList.map((item, index) => (
                <TR key={item.id} style={documentStyles.tableItems}>
                  <TD style={documentStyles.tableSrNo}>{index + 1}</TD>
                  <TD
                    style={{
                      ...documentStyles.tableTitle,
                      justifyContent: "flex-start",
                    }}
                  >
                    {item.title}
                  </TD>
                  <TD style={documentStyles.tableQty}>{item.qty}</TD>
                  <TD
                    style={{
                      ...documentStyles.tableUnitPrice,
                      justifyContent: "center",
                    }}
                  >
                    {item.unitPrice}
                  </TD>
                  <TD style={documentStyles.tableTotal}>
                    {item.unitPrice * item.qty}
                  </TD>
                </TR>
              ))}
            </Table>
          </View>
        )}
        {itemList.length > 0 && (
          <View
            style={{
              alignItems: "flex-end",
              fontSize: 16,
              marginTop: "50px",
            }}
          >
            <Text>
              Total : {netTotal} {currency === CURRENCY_INR ? "/-" : "$"}
            </Text>
          </View>
        )}
        {/* footer section */}
        {/* <View style={{ bottom: 0, position: "fixed" }}>
      <Text>asdas</Text>
      <View
        style={{
          borderBottom: `1px solid ${PRIMARY_COLOR}`,
          marginTop: 10,
        }}
      />
      <Text>asdasdasd asdas ds</Text>
    </View> */}
      </Page>
    </Document>
  );
};
