import {
  authSlice,
  adminSlice,
  designSlice,
  salesSlice,
  purchaseSlice,
  productionSlice,
  qualitySlice,
} from "./Slices";

import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    admin: adminSlice.reducer,
    design: designSlice.reducer,
    sales: salesSlice.reducer,
    purchase: purchaseSlice.reducer,
    production: productionSlice.reducer,
    quality: qualitySlice.reducer,
  },
});

export default store;

export const authAction = authSlice.actions;
export const adminAction = adminSlice.actions;
export const designAction = designSlice.actions;
export const salesAction = salesSlice.actions;
export const purchaseAction = purchaseSlice.actions;
export const productionAction = productionSlice.actions;
export const qualityAction = qualitySlice.actions;
