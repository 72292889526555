import { createSlice } from "@reduxjs/toolkit";
import { COMMON_NAVBAR_ITEM } from "Utils/constant";

const initialAuthState = {
  token: "",
  email: "",
  name: "",
  profileImg: "",
  id: "",
  hasAccess: [COMMON_NAVBAR_ITEM],
};

export const authSlice = createSlice({
  name: "auth",
  initialState:
    JSON.parse(localStorage.getItem("novel-auth")) ?? initialAuthState,
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      state.email = action.payload.user.email;
      state.name = action.payload.user.displayName;
      state.profileImg = action.payload.user.photoURL;
      state.id = action.payload.user.uid;
    },
    logout(state) {
      state.token = "";
      state.email = "";
      state.name = "";
      state.profileImg = "";
      state.id = "";
      state.hasAccess = [];
    },
    updatePermissions(state, action) {
      state.hasAccess = action.payload.hasAccess;
    },
  },
});
