import { Box, Button } from "@material-ui/core";
import { LOGIN_PATH } from "Utils/constant";
import { Link } from "react-router-dom";

const { Component } = require("react");

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: "",
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    if (error) {
      console.error(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
          gridGap="1rem"
        >
          <h3>Something went wrong, please try again! :(</h3>
          <a href={LOGIN_PATH}>
            <Button variant="contained">Login</Button>
          </a>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
