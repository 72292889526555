import React, { useMemo, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  CURRENCY_INR,
  CURRENCY_USD,
  PRIMARY_COLOR,
  documentStyles,
} from "Utils/constant";
import { v4 as uuid } from "uuid";
import { ContactSection, ListItem, PdfDocument } from "Components";

const TAX_RATE = 0.18;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow("Paperclips (Box)", 100, 1.15),
  createRow("Paper (Case)", 10, 45.99),
  createRow("Waste Basket", 2, 17.99),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const INITIAL_ITEM_DETAILS = {
  id: uuid(),
  title: "",
  description: "",
  qty: 1,
  unitPrice: 0,
  total: 0,
};

export const Quotation = () => {
  const [itemList, setItemList] = useState([]);
  const [itemDetails, setItemDetails] = useState(INITIAL_ITEM_DETAILS);
  const [currency, setCurrency] = useState(CURRENCY_INR);

  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    email: "",
    contactDetail: null,
    location: "",
    contactPerson: "",
    offerNo: null,
    date: new Date().toLocaleDateString(),
    dated: new Date().toLocaleDateString(),
    reference: "Mail",
  });

  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value);
  };

  const CurrencySelection = () => (
    <FormControl>
      <InputLabel id="currency-selection">Currency</InputLabel>
      <Select
        labelId="currency-selection"
        value={currency}
        label="Currency"
        onChange={handleChangeCurrency}
        style={{ width: "7rem" }}
      >
        <MenuItem value={CURRENCY_INR}>{CURRENCY_INR}</MenuItem>
        <MenuItem value={CURRENCY_USD}>{CURRENCY_USD}</MenuItem>
      </Select>
    </FormControl>
  );

  const MyDocument = useMemo(
    () => () =>
      (
        <PdfDocument
          currency={currency}
          customerDetails={customerDetails}
          itemList={itemList}
        />
      ),
    [customerDetails, itemList.length, currency]
  );

  // input details
  const handleCustomerDetail = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCustomerDetails((preDetails) => {
      return {
        ...preDetails,
        [name]: value,
      };
    });
  };

  const changeItemDetailHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setItemDetails((previousDetails) => {
      return {
        ...previousDetails,
        [name]: value,
      };
    });
  };

  const resetItemDetails = () => {
    setItemDetails(INITIAL_ITEM_DETAILS);
  };

  const addItemHandler = () => {
    if (itemDetails.title) {
      setItemList((previousList) => [
        ...previousList,
        {
          ...itemDetails,
          id: uuid(),
          total: itemDetails.qty * itemDetails.unitPrice,
        },
      ]);
      resetItemDetails();
    } else {
      alert("Enter valid details");
    }
  };

  const removeItemHandler = (id) => {
    const remainingList = itemList.filter((item) => item.id !== id);
    setItemList(remainingList);
  };

  return (
    <Box
      p={"0 2rem"}
      display="flex"
      gridGap={"1rem"}
      height="100%"
      width="100%"
      alignItems="flex-start"
      justifyContent="space-between"
      className="quotation-container"
    >
      <Box height="90dvh" overflow="auto">
        <ContactSection
          customerDetails={customerDetails}
          handleCustomerDetail={handleCustomerDetail}
        />
        <Box mt={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <h3>Item Details</h3>
            <CurrencySelection />
          </Box>
          {itemList.map((item) => (
            <ListItem
              key={item.id}
              itemDetails={item}
              changeItemDetailHandler={changeItemDetailHandler}
              onDeleteClick={removeItemHandler}
              showDelete
            />
          ))}
          <ListItem
            itemDetails={itemDetails}
            changeItemDetailHandler={changeItemDetailHandler}
          />
          <Button
            variant="contained"
            style={{
              backgroundColor: PRIMARY_COLOR,
              color: "white",
            }}
            onClick={addItemHandler}
          >
            Add Entry
          </Button>
        </Box>
      </Box>
      <PDFViewer style={documentStyles.viewer}>
        <MyDocument />
      </PDFViewer>
    </Box>
  );
};
