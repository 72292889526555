import React, { useState } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import { Box } from "@material-ui/core";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  viewer: {
    width: 500,
    height: 600,
  },
});

export const Invoice = () => {
  const [customerName, setCustomerName] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unitPrice, setUnitPrice] = useState("");

  const handleSaveInvoice = () => {
    const pdfData = generatePDF();
    savePDF(pdfData);
  };

  const calculateTotal = () => {
    const subtotal = parseFloat(quantity) * parseFloat(unitPrice);
    const tax = subtotal * 0.18;
    const total = subtotal + tax;
    return total;
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Invoice</Text>
          <Text>Customer Name: {customerName}</Text>
          <Text>Email: {email}</Text>
        </View>
        <View style={styles.body}>
          <Text>Description: {description}</Text>
          <View style={styles.itemRow}>
            <Text style={styles.itemColumn}>Item Name</Text>
            <Text style={styles.itemColumn}>Quantity</Text>
            <Text style={styles.itemColumn}>Unit Price</Text>
          </View>
          <View style={styles.itemRow}>
            <Text style={styles.itemColumn}>{itemName}</Text>
            <Text style={styles.itemColumn}>{quantity}</Text>
            <Text style={styles.itemColumn}>{unitPrice}</Text>
          </View>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Total:</Text>
            <Text>${calculateTotal().toFixed(2)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  const generatePDF = () => {
    const pdfData = (
      <Document>
        <Page orientation="portrait" size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.title}>Invoice</Text>
            <Text>Customer Name: {customerName}</Text>
            <Text>Email: {email}</Text>
          </View>
          <View style={styles.body}>
            <Text>Description: {description}</Text>
            <View style={styles.itemRow}>
              <Text style={styles.itemColumn}>Item Name</Text>
              <Text style={styles.itemColumn}>Quantity</Text>
              <Text style={styles.itemColumn}>Unit Price</Text>
            </View>
            <View style={styles.itemRow}>
              <Text style={styles.itemColumn}>{itemName}</Text>
              <Text style={styles.itemColumn}>{quantity}</Text>
              <Text style={styles.itemColumn}>{unitPrice}</Text>
            </View>
            <View style={styles.totalRow}>
              <Text style={styles.totalLabel}>Total:</Text>
              <Text>${calculateTotal().toFixed(2)}</Text>
            </View>
          </View>
        </Page>
      </Document>
    );
    return pdfData;
  };

  const savePDF = (pdfData) => {
    const blob = new Blob([pdfData], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "invoice.pdf";
    a.click();
  };

  return (
    <Box
      p={"0 2rem "}
      display="flex"
      gridGap={"1rem"}
      height="100%"
      width="100%"
    >
      <Box>
        <h2>Invoice Generator</h2>
        <div>
          <label htmlFor="customerName">Customer Name:</label>
          <input
            type="text"
            id="customerName"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="description">Description:</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="email">Email Address:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <h3>Item Details</h3>
          <div>
            <label htmlFor="itemName">Item Name:</label>
            <input
              type="text"
              id="itemName"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="quantity">Quantity:</label>
            <input
              type="number"
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="unitPrice">Unit Price:</label>
            <input
              type="number"
              id="unitPrice"
              value={unitPrice}
              onChange={(e) => setUnitPrice(e.target.value)}
            />
          </div>
        </div>

        <button onClick={handleSaveInvoice}>Save Invoice</button>
      </Box>
      <PDFViewer style={styles.viewer}>
        <MyDocument />
      </PDFViewer>
    </Box>
  );
};
