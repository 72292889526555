// import { Link } from "react-router-dom";
import "./NotFound.css";
// import { useSelector } from "react-redux";

export const NotFound = () => {
  // const { token } = useSelector((state) => state.auth);

  return (
    <div className="page-not-found">
      <div className="error-number">
        <h1>4</h1>
        <h1>0</h1>
        <h1>4</h1>
      </div>
      <h2>Page Not Found</h2>
      {/* <Link to={token ? "/dashboard" : "/"} replace>
        <button className="btn primary-text-btn-lg">Back to Home </button>
      </Link> */}
    </div>
  );
};
