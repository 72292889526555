import { createSlice } from "@reduxjs/toolkit";

const initialQualityState = {
  qualityCheckCompleted: [],
  qualityCheckRemaining: [],
};

export const qualitySlice = createSlice({
  name: "quality",
  initialState: initialQualityState,
  reducers: {
    updateQualityCheckCompleted(state, action) {
      state.qualityCheckCompleted = action.payload;
    },
    updateQualityCheckRemaining(state, action) {
      state.qualityCheckRemaining = action.payload;
    },
  },
});
