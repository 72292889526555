import { createSlice } from "@reduxjs/toolkit";

const initialSalesState = {
  receivedOrders: [],
  clientList: [],
  cancelledOrders: [],
  priceList: [],
  sentQuotations: [],
};

export const salesSlice = createSlice({
  name: "sales",
  initialState: initialSalesState,
  reducers: {
    updateReceivedOrders(state, action) {
      state.receivedOrders = action.payload;
    },
    updateClientList(state, action) {
      state.clientList = action.payload;
    },
    updateCancelledOrders(state, action) {
      state.cancelledOrders = action.payload;
    },
    updatePriceList(state, action) {
      state.priceList = action.payload;
    },
    updateSentQuotations(state, action) {
      state.sentQuotations = action.payload;
    },
  },
});
