import {
  Admin,
  Design,
  Home,
  Invoice,
  Orders,
  Purchase,
  Quotation,
  Sales,
} from "./Pages";
import { Login } from "./Pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ProtectedRoute } from "./Components/ProtectedRoute/ProtectedRoute";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ScrollToTop } from "./Components";
import { NotFound } from "./Pages/NotFound/NotFound";
import { ToastContainer } from "react-toastify";
import { BodyWrapper } from "./Components/UI/Wrapper/BodyWrapper";
import ErrorBoundary from "Components/ErrorBoundary/ErrorBoundary";
import { Box } from "@material-ui/core";
import {
  ACCOUNTS_PATH,
  ADMIN_PATH,
  DESIGN_PATH,
  DISPATCH_PATH,
  HOME_PATH,
  INVOICE_PATH,
  LOGIN_PATH,
  ORDERS_PATH,
  PURCHASE_PATH,
  QUOTATION_PATH,
  SALES_PATH,
  SEPERATE_SALE_PATH,
  SEPERATE_WORKORDER_PATH,
  SETTINGS_PATH,
  WORKORDER_PATH,
} from "Utils/constant";
import { getUserPermissions } from "Services/loginHandler";

function App() {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState?.token) {
      dispatch(getUserPermissions(authState.id));
      localStorage.setItem("novel-auth", JSON.stringify(authState));
    }
  }, [authState.token]);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <ScrollToTop>
          <ToastContainer className="toast-container" />

          <BodyWrapper>
            <Box className="App" style={{ padding: "1.5rem 0" }}>
              <Routes>
                <Route
                  exact
                  path={HOME_PATH}
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={WORKORDER_PATH}
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={QUOTATION_PATH}
                  element={
                    <ProtectedRoute>
                      <Quotation />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={SALES_PATH}
                  element={
                    <ProtectedRoute>
                      <Sales />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={PURCHASE_PATH}
                  element={
                    <ProtectedRoute>
                      <Purchase />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={SETTINGS_PATH}
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={ACCOUNTS_PATH}
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={DISPATCH_PATH}
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={ORDERS_PATH}
                  element={
                    <ProtectedRoute>
                      <Orders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={DESIGN_PATH}
                  element={
                    <ProtectedRoute>
                      <Design />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={SEPERATE_WORKORDER_PATH}
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={SEPERATE_SALE_PATH}
                  element={
                    <ProtectedRoute>
                      <Sales />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={ADMIN_PATH}
                  element={
                    <ProtectedRoute>
                      <Admin />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path={INVOICE_PATH}
                  element={
                    <ProtectedRoute>
                      <Invoice />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/purchase/:id"
                  element={
                    <ProtectedRoute>
                      <Purchase />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/settings/account/:id"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/dispatch/:id"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/orders/:id"
                  element={
                    <ProtectedRoute>
                      <Orders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/design/:id"
                  element={
                    <ProtectedRoute>
                      <Design />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/workorder/clients/:id"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/sales/clients/:id"
                  element={
                    <ProtectedRoute>
                      <Sales />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/purchase/seller/:id"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/dispatch/clients/:id"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  exact
                  path="/design/client/:id"
                  element={
                    <ProtectedRoute>
                      <Design />
                    </ProtectedRoute>
                  }
                />
                <Route exact path={LOGIN_PATH} element={<Login />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <p className="text-center">© Novel Valves India Pvt Ltd 2022</p>
            </Box>
          </BodyWrapper>
        </ScrollToTop>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
