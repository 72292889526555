import { createSlice } from "@reduxjs/toolkit";

const initialPurchaseState = {
  inStock: [],
  placedOrders: [],
  activeSuppliers: [],
  bannedSuppliers: [],
  receivedQuotations: [],
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState: initialPurchaseState,
  reducers: {
    updateInStock(state, action) {
      state.inStock = action.payload;
    },
    updatePlacedOrders(state, action) {
      state.placedOrders = action.payload;
    },
    updateActiveSuppliers(state, action) {
      state.activeSuppliers = action.payload;
    },
    updateBannedSuppliers(state, action) {
      state.bannedSuppliers = action.payload;
    },
    updateReceivedQuotations(state, action) {
      state.receivedQuotations = action.payload;
    },
  },
});
