import React, { useState } from "react";
import logo from "../Data/Logo/logo.png";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { loginHandler } from "Services/loginHandler";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authAction } from "Store/store";

export const Login = () => {
  const [user, setUser] = useState({ email: "", password: "" });

  const authState = useSelector((state) => state.auth);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const onloginSubmit = (e) => {
    e.preventDefault();
    dispatch(loginHandler(user.email, user.password, navigate));
  };

  const onInputChange = (e) => {
    setUser((preData) => {
      return { ...preData, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <h1>Novel Valves - Work Management System</h1>
        <img src={logo} alt="logo" className="brand-logo" />
      </div>
      {!authState?.token ? (
        <form onSubmit={onloginSubmit}>
          <div className="outline-email-input">
            <label>
              Email *
              <input
                type="email"
                name="email"
                required
                autoComplete="email"
                placeholder="Enter email"
                onChange={onInputChange}
              />
            </label>
          </div>
          <div className="outline-password-input wrong-password">
            <label>
              Password *
              <input
                type="password"
                name="password"
                autoComplete="current-password"
                placeholder="Enter password"
                required
                onChange={onInputChange}
              />
            </label>
          </div>
          <button className="btn primary-btn" type="submit">
            Login
          </button>
          <h2 className="login-text">
            forgot password? contact system administrator
          </h2>
        </form>
      ) : (
        <div className="logged-cta">
          <Link to="/">
            <button className="btn primary-btn">Go to Dashboard</button>
          </Link>
          <button
            onClick={() => {
              dispatch(authAction.logout());
              localStorage.removeItem("novel-auth");
            }}
            className="btn primary-outline-btn"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};
