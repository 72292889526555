import { signInWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth, doc, firestore, getDoc } from "../firebase.config";
import { authAction } from "Store/store";

export const loginHandler = (email, password, navigate) => async (dispatch) => {
  try {
    const response = await signInWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    );
    if (response) {
      dispatch(
        authAction.login({
          token: response.user.accessToken,
          user: response.user.providerData[0],
        })
      );
      navigate("/", { replace: true });
    }
  } catch (error) {
    alert("Wrong Credentials!");
  }
};

export const getUserPermissions = (email) => async (dispatch) => {
  const docRef = doc(firestore, "users", email);

  try {
    const response = await getDoc(docRef);
    dispatch(
      authAction.updatePermissions({
        hasAccess: response.data().hasAccess,
      })
    );
  } catch (error) {
    console.error(error);
  }
};
