import { Box, Button, Input } from "@material-ui/core";
import React from "react";

export const ListItem = ({
  itemDetails,
  changeItemDetailHandler,
  onDeleteClick,
  showDelete = false,
}) => {
  return (
    <Box
      border="1px solid rgba(0,0,0,0.3)"
      borderRadius="8px"
      display="flex"
      gridGap={8}
      flexWrap="wrap"
      margin={"0.6rem 0"}
      padding={1}
    >
      <Box width="100%">
        <label>Title</label>
        <Input
          style={{ width: "90%" }}
          value={itemDetails.title}
          name="title"
          type="text"
          onChange={changeItemDetailHandler}
        />
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        gridGap={6}
        width="100%"
        alignItems="center"
      >
        <Box width="9rem" display="flex" alignItems="center">
          <label>Qty</label>
          <Input
            style={{ maxWidth: "5rem" }}
            value={itemDetails.qty}
            name="qty"
            type="number"
            onChange={changeItemDetailHandler}
          />
        </Box>
        <Box width="13rem" display="flex" alignItems="center">
          <label>Unit Price </label>
          <Input
            value={itemDetails.unitPrice}
            style={{ width: "8rem" }}
            name="unitPrice"
            type="number"
            onChange={changeItemDetailHandler}
          />
        </Box>
        {showDelete && (
          <Box width="fit-content" display="flex">
            <Button
              variant="outlined"
              size="small"
              onClick={() => onDeleteClick(itemDetails.id)}
            >
              delete
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
