import { createSlice } from "@reduxjs/toolkit";

const initialAdminState = {
  workorders: [],
  employees: [],
  completedOrders: [],
  deliveredOrders: [],
};

export const adminSlice = createSlice({
  name: "admin",
  initialState: initialAdminState,
  reducers: {
    updateWorkorders(state, action) {
      state.workorders = action.payload;
    },
    updateEmployees(state, action) {
      state.employees = action.payload;
    },
    updateCompletedOrders(state, action) {
      state.completedOrders = action.payload;
    },
    updateDeliveredOrders(state, action) {
      state.deliveredOrders = action.payload;
    },
  },
});
